import React from "react";
import { Link, useLocation } from "react-router-dom";
import Title from "../components/Title";
import Header from "../components/header";
import Footer from "../components/footer";
import BackToTop from "../components/Backtotop";
import { BreadcrumbService } from "../components/Breadcrumb";
import Slide1 from "../assets/img/JK_Home_Slide1.jpg";
import Slide2 from "../assets/img/JK_Home_Slide2.jpg";
import Slide3 from "../assets/img/JK_Home_Slide3.jpg";
import Slide4 from "../assets/img/JK_Home_Slide1.jpg";
import Slide5 from "../assets/img/JK_Home_Slide1.jpg";

const Pestcontrol = () => {
  const location = useLocation();
  return (
    <div>
      <Title title={"Pest Control Services - JK Facility Services"} />
      <Header />
      <BreadcrumbService
        pagename={"Pest Control Services"}
        shortform={"Pest Control"}
      />
      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-8">
              <div className="entry entry-single">
                <img
                  src={Slide1}
                  alt="Pest Control Slide 1"
                  className="img-fluid"
                />
                <h2 className="entry-title">
                  <Link to="/contact">Effective Pest Control Solutions</Link>
                </h2>
                <div className="entry-content">
                  <p>
                    JK Facility Services offers comprehensive pest control
                    services to eliminate pests and protect your property. Our
                    experienced team uses environmentally friendly methods to
                    address various pest infestations and ensure a safe
                    environment for your clients and employees.
                  </p>
                  <blockquote>
                    <p>
                      We are committed to delivering exceptional pest control
                      solutions that are tailored to your needs and designed to
                      prevent future infestations.
                    </p>
                  </blockquote>
                  <p>
                    Whether it's dealing with rodents, insects, or other pests,
                    we have the expertise and resources to effectively manage
                    pest issues and maintain a pest-free environment.
                  </p>
                </div>
                <div className="image-collage">
                  <div className="gallery">
                    <div className="collage-item">
                      <img src={Slide2} alt="Additional 1" />
                    </div>
                    <div className="collage-item">
                      <img src={Slide3} alt="Additional 2" />
                    </div>
                    <div className="collage-item">
                      <img src={Slide4} alt="Additional 3" />
                    </div>
                    <div className="collage-item">
                      <img src={Slide5} alt="Additional 4" />
                    </div>
                    <div className="collage-item">
                      <img src={Slide5} alt="Additional 5" />
                    </div>
                    <div className="collage-item">
                      <img src={Slide5} alt="Additional 6" />
                    </div>
                    <div className="collage-item">
                      <img src={Slide3} alt="Additional 7" />
                    </div>
                    <div className="collage-item">
                      <img src={Slide3} alt="Additional 8" />
                    </div>
                    <div className="collage-item">
                      <img src={Slide5} alt="Additional 9" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <h3 className="sidebar-title">Explore Other Services</h3>
                <div
                  className={`post-item ${
                    location.pathname === "/facilitymanagement" ? "active" : ""
                  }`}
                >
                  <img src={Slide2} alt="Facility Management" />
                  <h4>
                    <Link to="/facilitymanagement">
                      Facility Management Services
                    </Link>
                  </h4>
                </div>
                <div
                  className={`post-item ${
                    location.pathname === "/housekeeping" ? "active" : ""
                  }`}
                >
                  <img src={Slide2} alt="Housekeeping" />
                  <h4>
                    <Link to="/housekeeping">Housekeeping Services</Link>
                  </h4>
                </div>
                <div
                  className={`post-item ${
                    location.pathname === "/security" ? "active" : ""
                  }`}
                >
                  <img src={Slide3} alt="Security" />
                  <h4>
                    <Link to="/security">Security Services</Link>
                  </h4>
                </div>
                <div
                  className={`post-item ${
                    location.pathname === "/hospitality" ? "active" : ""
                  }`}
                >
                  <img src={Slide3} alt="Hospitality" />
                  <h4>
                    <Link to="/hospitality">Hospitality Services</Link>
                  </h4>
                </div>
                <div
                  className={`post-item ${
                    location.pathname === "/technicalsupport" ? "active" : ""
                  }`}
                >
                  <img src={Slide3} alt="Electro Mechanic" />
                  <h4>
                    <Link to="/technicalsupport">
                      Electro Mechanic Services
                    </Link>
                  </h4>
                </div>
                <div
                  className={`post-item ${
                    location.pathname === "/carpetcleaning" ? "active" : ""
                  }`}
                >
                  <img src={Slide3} alt="Carpet Shampooing" />
                  <h4>
                    <Link to="/carpetcleaning">Carpet Shampooing Services</Link>
                  </h4>
                </div>
                <div
                  className={`post-item ${
                    location.pathname === "/pestcontrol" ? "active" : ""
                  }`}
                >
                  <img src={Slide3} alt="Pest Control" />
                  <h4>
                    <Link to="/pestcontrol">Pest Control Services</Link>
                  </h4>
                </div>
                <div
                  className={`post-item ${
                    location.pathname === "/landscaping" ? "active" : ""
                  }`}
                >
                  <img src={Slide3} alt="Landscaping" />
                  <h4>
                    <Link to="/landscaping">Landscaping Services</Link>
                  </h4>
                </div>
                <div
                  className={`post-item ${
                    location.pathname === "/payrollmanagement" ? "active" : ""
                  }`}
                >
                  <img src={Slide3} alt="Payroll Management" />
                  <h4>
                    <Link to="/payrollmanagement">
                      Payroll Management Services
                    </Link>
                  </h4>
                </div>
                <div
                  className={`post-item ${
                    location.pathname === "/outsourcing" ? "active" : ""
                  }`}
                >
                  <img src={Slide3} alt="Outsourcing" />
                  <h4>
                    <Link to="/outsourcing">Outsourcing Services</Link>
                  </h4>
                </div>
                <div
                  className={`post-item ${
                    location.pathname === "/productionsupport" ? "active" : ""
                  }`}
                >
                  <img src={Slide3} alt="Production Support" />
                  <h4>
                    <Link to="/productionsupport">
                      Production Support Services
                    </Link>
                  </h4>
                </div>
                <div
                  className={`post-item ${
                    location.pathname === "/housecleaning" ? "active" : ""
                  }`}
                >
                  <img src={Slide3} alt="House Cleaning" />
                  <h4>
                    <Link to="/housecleaning">House Cleaning Services</Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BackToTop />
      <Footer />
    </div>
  );
};

export default Pestcontrol;
