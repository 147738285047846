import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRight } from 'react-bootstrap-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../assets/img/logo_white.png";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer id="footer">
      <div className="footer-top">
        <Container>
          <Row>
            <Col lg={4} md={6}>
              <div className="footer-info">
                <Link to="/" className="logo me-auto" onClick={scrollToTop}>
                  <img
                    src={logo}
                    alt="JK Facility Management"
                    className="img-fluid"
                    style={{ maxWidth: "250px", maxHeight: "100px" }}
                  />
                </Link>
                <Link
                  to="https://www.google.com/maps/search/?api=1&query=JK Complex, TVS Main Road, Alapakkam, Tamilnadu, India"
                  target="_blank"
                >
                  <p>
                    No.34/6, JK Complex,
                    <br /> TVS Main Road, Alapakkam,
                    <br /> Chennai - 600 063, Tamilnadu, INDIA.
                  </p>
                </Link>
                <br />
                <p>
                  Phone: <strong>+91-7299573102, +91-9940242778</strong>
                  <br />
                  Email:{" "}
                  <a
                    href="mailto:JKfacilitymanagement2022@gmail.com?subject=Inquiry about Facility Services"
                    className="footer-email"
                  >
                    enquiry@jkfacilityservices.com
                  </a>
                </p>
                <div className="social-links mt-3">
                  <Link to="#" className="facebook">
                    <FontAwesomeIcon icon={faFacebook} />
                  </Link>
                  <Link to="#" className="instagram">
                    <FontAwesomeIcon icon={faInstagram} />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/jk-integrated-facility-management/"
                    className="linkedin"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </Link>
                </div>
              </div>
            </Col>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/facilitymanagement" onClick={scrollToTop}>
                    Facility Management Services
                  </Link>
                </li>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/housekeeping" onClick={scrollToTop}>
                    Housekeeping Services
                  </Link>
                </li>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/security" onClick={scrollToTop}>
                    Security Services
                  </Link>
                </li>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/hospitality" onClick={scrollToTop}>
                    Hospitality Services
                  </Link>
                </li>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/technicalsupport" onClick={scrollToTop}>
                    Electro Mechanic Services
                  </Link>
                </li>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/carpetcleaning" onClick={scrollToTop}>
                    Carpet Shampooing Services
                  </Link>
                </li>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/pestcontrol" onClick={scrollToTop}>
                    Pest Control Services
                  </Link>
                </li>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/landscaping" onClick={scrollToTop}>
                    Landscaping Services
                  </Link>
                </li>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/payrollmanagement" onClick={scrollToTop}>
                    Payroll Management Services
                  </Link>
                </li>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/outsourcing" onClick={scrollToTop}>
                    Outsourcing Services
                  </Link>
                </li>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/productionsupport" onClick={scrollToTop}>
                    Production Support Services
                  </Link>
                </li>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/housecleaning" onClick={scrollToTop}>
                    House Cleaning Services
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Quick Links</h4>
              <ul>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/" onClick={scrollToTop}>
                    Home
                  </Link>
                </li>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/verticals" onClick={scrollToTop}>
                    Verticals
                  </Link>
                </li>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/qualitytraining" onClick={scrollToTop}>
                    Quality Training
                  </Link>
                </li>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/gallery" onClick={scrollToTop}>
                    Gallery
                  </Link>
                </li>
                <li>
                <ArrowRight />&nbsp;{" "}
                  <Link to="/about" onClick={scrollToTop}>
                    About
                  </Link>
                </li>
                <li>
                  <ArrowRight />&nbsp;{" "}
                  <Link to="/contact" onClick={scrollToTop}>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </Row>
        </Container>
      </div>

      <Container>
        <div className="copyright">
          &copy; {currentYear} Copyright{" "}
          <strong>
            <span>JK Facility Management</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          <Link to="http://www.JKfacilityservices.com">
            www.JKfacilityservices.com
          </Link>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
