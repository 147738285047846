import React from "react";
import "../assets/css/style.css";
import Title from "../components/Title";
import Header from "../components/header";
import Hero from "../components/hero";
import BackToTop from "../components/Backtotop";
import About from "../components/about";
import Accom from "../components/Accom";
import Services from "../components/services";
import Testimonial from "../components/Testimonial";
import Cta from "../components/Cta";
import Footer from "../components/footer";

const Home = () => {
  return (
    <div>
      <Title title={"JK Facility Services"} />
      <Header />
      <Hero />
      <BackToTop />
      <About />
      <Services />
      <Accom />
      <Testimonial />
      <Cta />
      <Footer />
    </div>
  );
};

export default Home;
