import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const About = () => {
  const [isVisible, setIsVisible] = useState(false);
  const statsRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2, // Trigger when 20% of the element is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const currentRef = statsRef.current; // Copy statsRef.current to a variable inside the effect

    if (currentRef) {
      // Use the variable inside the effect
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        // Use the variable inside the cleanup function
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section id="about" className="custom-about-section">
      <div className="custom-container">
        <div className="custom-row">
          <div className="custom-col-lg-6">
            <h2 className="custom-about-title">
              Empowering Your Business Growth
            </h2>
            <p className="custom-about-description">
              JK Global Services is dedicated to providing top-notch Facility
              Management, Security, Housekeeping, Maintenance, Technical
              Support, and Pest Control services in South India. We focus on
              enhancing your business success through reliable and
              cost-effective solutions.
            </p>
            <ul className="custom-about-list">
              <li>
                <i className="ri-check-double-line"></i> Affordable pricing with
                uncompromising quality
              </li>
              <li>
                <i className="ri-check-double-line"></i> Skilled professionals
                adhering to industry standards
              </li>
              <li>
                <i className="ri-check-double-line"></i> Ongoing training and
                development programs
              </li>
            </ul>
            <p className="custom-about-team">
              Our team includes skilled laborers, warehouse experts, packaging
              specialists, logistics professionals, procurement agents, and
              sales & marketing executives.
            </p>
            <Link
              to="/about"
              className="custom-about-link"
              onClick={scrollToTop}
            >
              Know More
            </Link>
          </div>
          <div className="custom-col-lg-6" ref={statsRef}>
            <div className="custom-about-stats">
              <h3>Our Achievements</h3>
              <ul className="custom-stats-list">
                <li>
                  <span className="custom-stats-value">
                    {isVisible && <CountUp start={0} end={6} duration={4} />}
                  </span>
                  <span className="custom-stats-label">Satisfied Clients</span>
                </li>
                <li>
                  <span className="custom-stats-value">
                    {isVisible && <CountUp start={0} end={63} duration={2} />}
                  </span>
                  <span className="custom-stats-label">
                    Team Members Empowered
                  </span>
                </li>
                <li>
                  <span className="custom-stats-value">
                    {isVisible && (
                      <CountUp
                        start={0}
                        end={98}
                        duration={2}
                        decimals={1}
                        suffix="%"
                      />
                    )}
                  </span>
                  <span className="custom-stats-label">
                    Customer Satisfaction Rate
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
