import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ pagename }) => {
  return (
    <section id="breadcrumbs" className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{pagename}</h2>
          <ol>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>{pagename}</li>
          </ol>
        </div>
      </div>
    </section>
  );
};

const BreadcrumbService = ({ pagename, shortform }) => {
  return (
    <section id="breadcrumbs" className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{pagename}</h2>
          <ol>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span>Services</span>
            </li>
            <li>{shortform}</li>
          </ol>
        </div>
      </div>
    </section>
  );
};

export { Breadcrumb, BreadcrumbService };
