import React from 'react';
import { Link } from 'react-router-dom';


const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

const Cta = () => {
  return (
    <section className="cta-section">
      <div className="cta-container">
        <h2>Ready to Get Started?</h2>
        <p>Take the first step towards improving your facility. Contact us today!</p>
        <div className="cta-buttons">
        <Link to="/contact" className="cta-button" onClick={scrollToTop}>Contact Us</Link>
        </div>
      </div>
    </section>
  );
};

export default Cta;
