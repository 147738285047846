import React from "react";
import Title from "../components/Title";
import Header from "../components/header";
import Footer from "../components/footer";
import Backtotop from "../components/Backtotop";
import { Breadcrumb } from "../components/Breadcrumb";

const Qualitytraining = () => {
  return (
    <div>
      <Title title={"Quality Training - JK Facility Services"} />
      <Header />
      <Breadcrumb pagename={"Quality Training"} />
      <Backtotop />
      <Footer />
    </div>
  );
};

export default Qualitytraining;
