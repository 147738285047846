import React, { useState, useEffect, useRef } from "react";
import SolarEnergy from "../assets/img/solarenergy.jpg";
import WaterManagement from "../assets/img/WaterManagement.jpg";
import TreePlanting from "../assets/img/TreePlanting.jpg";


const Accom = () => {
  const [isSectionInView, setIsSectionInView] = useState(false);
  const [isSolarVisible, setIsSolarVisible] = useState(false);
  const [isWaterVisible, setIsWaterVisible] = useState(false);
  const [isPlantingVisible, setIsPlantingVisible] = useState(false);
  const sectionRef = useRef(null);
  const solarRef = useRef(null);
  const waterRef = useRef(null);
  const plantingRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const elementTop = sectionRef.current.getBoundingClientRect().top;
        const isVisible = elementTop < window.innerHeight * 0.7;
        setIsSectionInView(isVisible);

        if (isVisible) {
          setTimeout(() => setIsSolarVisible(true), 200);
          setTimeout(() => setIsWaterVisible(true), 600);
          setTimeout(() => setIsPlantingVisible(true), 1000);
          window.removeEventListener("scroll", handleScroll);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className={`unique-section ${isSectionInView ? "visible" : ""}`} ref={sectionRef}>
      <div className="unique-container">
        <h2 className={`section-title ${isSectionInView ? "visible" : ""}`}>Our Green Initiatives</h2>
        <p className={`section-description ${isSectionInView ? "visible" : ""}`}>
          Explore our sustainable practices and eco-friendly initiatives aimed
          at preserving the environment, integrated into our facility services.
        </p>
        <div className="initiatives">
          <div className={`initiative-item ${isSolarVisible ? "visible" : ""}`} ref={solarRef}>
            <div className="card">
              <div className="card-front">
                <h3>Solar Energy</h3>
                <p>
                  Implementing solar panel technology to harness clean,
                  renewable energy sources.
                </p>
              </div>
              <div className="card-back">
                <img src={SolarEnergy} alt="Solar Energy" />
              </div>
            </div>
          </div>
          <div className={`initiative-item ${isWaterVisible ? "visible" : ""}`} ref={waterRef}>
            <div className="card">
              <div className="card-front">
                <h3>Water Conservation</h3>
                <p>
                  Adopting water-saving technologies and practices to
                  conserve water resources.
                </p>
              </div>
              <div className="card-back">
                <img src={WaterManagement} alt="Water Conservation" />
              </div>
            </div>
          </div>
          <div className={`initiative-item ${isPlantingVisible ? "visible" : ""}`} ref={plantingRef}>
            <div className="card">
              <div className="card-front">
                <h3>Planting</h3>
                <p>
                  Engaging in tree planting initiatives to improve air quality
                  and biodiversity.
                </p>
              </div>
              <div className="card-back">
                <img src={TreePlanting} alt="Tree Planting" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Accom;
