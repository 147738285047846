import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Hp from '../assets/img/hp1.svg';
import Ls from '../assets/img/ls1.svg';
import Hk from '../assets/img/hk1.svg';
import CC from '../assets/img/cc1.svg';
import Ts from '../assets/img/ts1.svg';
import Sg from '../assets/img/sg1.svg';
import Os from '../assets/img/os1.svg';
import Pc from '../assets/img/pc1.svg';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const Services = () => {
  const [isVisibleHk, setIsVisibleHk] = useState(false);
  const [isVisibleHp, setIsVisibleHp] = useState(false);
  const [isVisiblePc, setIsVisiblePc] = useState(false);
  const [isVisibleLs, setIsVisibleLs] = useState(false);
  const [isVisibleCC, setIsVisibleCc] = useState(false);
  const [isVisibleTs, setIsVisibleTs] = useState(false);
  const [isVisibleSg, setIsVisibleSg] = useState(false);
  const [isVisibleOs, setIsVisibleOs] = useState(false);

  const hkRef = useRef(null);
  const hpRef = useRef(null);
  const pcRef = useRef(null);
  const lsRef = useRef(null);
  const ccRef = useRef(null);
  const tsRef = useRef(null);
  const sgRef = useRef(null);
  const osRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      threshold: 0,
    };

    const hkRefCurrent = hkRef.current;
    const hpRefCurrent = hpRef.current;
    const pcRefCurrent = pcRef.current;
    const lsRefCurrent = lsRef.current;
    const ccRefCurrent = ccRef.current;
    const tsRefCurrent = tsRef.current;
    const sgRefCurrent = sgRef.current;
    const osRefCurrent = osRef.current;

    const observerHk = new IntersectionObserver(([entry]) => {
      setIsVisibleHk(entry.isIntersecting);
    }, observerOptions);
    if (hkRefCurrent) {
      observerHk.observe(hkRefCurrent);
    }

    const observerHp = new IntersectionObserver(([entry]) => {
      setIsVisibleHp(entry.isIntersecting);
    }, observerOptions);
    if (hpRefCurrent) {
      observerHp.observe(hpRefCurrent);
    }

    const observerPc = new IntersectionObserver(([entry]) => {
      setIsVisiblePc(entry.isIntersecting);
    }, observerOptions);
    if (pcRefCurrent) {
      observerPc.observe(pcRefCurrent);
    }

    const observerLs = new IntersectionObserver(([entry]) => {
      setIsVisibleLs(entry.isIntersecting);
    }, observerOptions);
    if (lsRefCurrent) {
      observerLs.observe(lsRefCurrent);
    }

    const observerCc = new IntersectionObserver(([entry]) => {
      setIsVisibleCc(entry.isIntersecting);
    }, observerOptions);
    if (ccRefCurrent) {
      observerCc.observe(ccRefCurrent);
    }

    const observerTs = new IntersectionObserver(([entry]) => {
      setIsVisibleTs(entry.isIntersecting);
    }, observerOptions);
    if (tsRefCurrent) {
      observerTs.observe(tsRefCurrent);
    }

    const observerSg = new IntersectionObserver(([entry]) => {
      setIsVisibleSg(entry.isIntersecting);
    }, observerOptions);
    if (sgRefCurrent) {
      observerSg.observe(sgRefCurrent);
    }

    const observerOs = new IntersectionObserver(([entry]) => {
      setIsVisibleOs(entry.isIntersecting);
    }, observerOptions);
    if (osRefCurrent) {
      observerOs.observe(osRefCurrent);
    }

    return () => {
      if (hkRefCurrent) {
        observerHk.unobserve(hkRefCurrent);
      }
      if (hpRefCurrent) {
        observerHp.unobserve(hpRefCurrent);
      }
      if (pcRefCurrent) {
        observerPc.unobserve(pcRefCurrent);
      }
      if (lsRefCurrent) {
        observerLs.unobserve(lsRefCurrent);
      }
      if (ccRefCurrent) {
        observerCc.unobserve(ccRefCurrent);
      }
      if (tsRefCurrent) {
        observerTs.unobserve(tsRefCurrent);
      }
      if (sgRefCurrent) {
        observerSg.unobserve(sgRefCurrent);
      }
      if (osRefCurrent) {
        observerOs.unobserve(osRefCurrent);
      }
    };
  }, []);

  
  return (
    <section id="services" className="services">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
          <div className={`icon-box ${isVisibleHk ? 'visible left-slide' : 'left-slide'}`} ref={hkRef}>
              <i><img src={Hk} alt="Housekeeping Icon" /></i>
              <h4><Link to="/housekeeping" onClick={scrollToTop}>Housekeeping</Link></h4>
              <p>Dedicated to keeping your premises clean, and the team comes with a professional background and experience.</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
          <div className={`icon-box ${isVisibleHp ? 'visible right-slide' : 'right-slide'}`} ref={hpRef}>
              <i><img src={Hp} alt="Hospitality Icon" /></i>
              <h4><Link to="/hospitality" onClick={scrollToTop}>Hospitality</Link></h4>
              <p>Well-groomed and skilled staff would be dealing with the entire job of operations with an ostensible feel of expertise and finesse.</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
          <div className={`icon-box ${isVisiblePc ? 'visible left-slide' : 'left-slide'}`} ref={pcRef}>
              <i><img src={Pc} alt="Pest Control Icon" /></i>
              <h4><Link to="/pestcontrol" onClick={scrollToTop}>Pest Control</Link></h4>
              <p>Innovative treatments with a wide variety of tested and verified solutions make our Pest Control Service the best in the market.</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
          <div className={`icon-box ${isVisibleLs ? 'visible right-slide' : 'right-slide'}`} ref={lsRef}>
              <i><img src={Ls} alt="Landscaping Icon" /></i>
              <h4><Link to="/landscaping" onClick={scrollToTop}>Landscaping</Link></h4>
              <p>Your yard maintains its curb appeal throughout the year with experienced gardeners under the control of our Horticulturist.</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
          <div className={`icon-box ${isVisibleCC ? 'visible left-slide' : 'left-slide'}`} ref={ccRef}>
              <i><img src={CC} alt="Carpet Cleaning Icon" /></i>
              <h4><Link to='/carpetcleaning' onClick={scrollToTop}>Carpet Cleaning</Link></h4>
              <p>We treat each spot & stain individually in the carpet with the appropriate cleaning product to ensure complete removal.</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
          <div className={`icon-box ${isVisibleTs ? 'visible right-slide' : 'right-slide'}`} ref={tsRef}>
              <i><img src={Ts} alt="Electro Mechanic Services Icon" /></i>
              <h4><Link to="/technicalsupport" onClick={scrollToTop}>Electro Mechanic Services</Link></h4>
              <p>Our experts retain functionality in the best manner by providing different maintenance services based on your requirements.</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
          <div className={`icon-box ${isVisibleSg ? 'visible left-slide' : 'left-slide'}`} ref={sgRef}>
              <i><img src={Sg} alt="Security Services Icon" /></i>
              <h4><Link to="/security" onClick={scrollToTop}>Security Services</Link></h4>
              <p>We provide a wide range of reliable Security Services with trained Security Guards and Supervisors to all Industries.</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
          <div className={`icon-box ${isVisibleOs ? 'visible right-slide' : 'right-slide'}`} ref={osRef}>
              <i><img src={Os} alt="Outsourcing Icon" /></i>
              <h4><Link to="/outsourcing" onClick={scrollToTop}>Outsourcing</Link></h4>
              <p>We follow our client's request and adopt the best technology to provide work with high accuracy.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
