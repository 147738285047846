import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/home';
import Facilitymanagement from './pages/Facilitymanagement';
import Housekeeping from './pages/Housekeeping';
import Security from './pages/Security';
import Hospitality from './pages/Hospitality';
import Techsupport from './pages/Techsupport';
import Carpetcleaning from './pages/Carpetcleaning';
import Pestcontrol from './pages/Pestcontrol';
import Landscaping from './pages/Landscaping';
import Payroll from './pages/Payroll';
import Outsourcing from './pages/Outsourcing';
import Prodsupport from './pages/Prodsupport';
import Housecleaning from './pages/Housecleaning';
import Verticals from './pages/Verticals';
import Qualitytraining from './pages/Qualitytraining';
import Gallery from './pages/Gallery';
import Aboutpage from './pages/Aboutpage';
import Contact from './pages/Contact';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/facilitymanagement" element={<Facilitymanagement />} />
        <Route path="/housekeeping" element={<Housekeeping />} />
        <Route path="/security" element={<Security />} />
        <Route path="/hospitality" element={<Hospitality />} />
        <Route path="/technicalsupport" element={<Techsupport />} />
        <Route path="/carpetcleaning" element={<Carpetcleaning />} />
        <Route path="/pestcontrol" element={<Pestcontrol />} />
        <Route path="/landscaping" element={<Landscaping />} />
        <Route path="/payrollmanagement" element={<Payroll />} />
        <Route path="/outsourcing" element={<Outsourcing />} />
        <Route path="/productionsupport" element={<Prodsupport />} />
        <Route path="/housecleaning" element={<Housecleaning />} />
        <Route path="/verticals" element={<Verticals />} />
        <Route path="/qualitytraining" element={<Qualitytraining />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/About" element={<Aboutpage />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
