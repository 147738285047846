import React from "react";

const Testimonial = () => {
  return (
    <section className="testimonials-section">
      <div className="testimonials-container">
        <h2>What Our Customers Say</h2>
        <div className="testimonial">
          <div className="testimonial-content">
            <p>
              "The JK Facility Management team exudes remarkable energy and
              professionalism in their operations at the Isha Anandham
              Community. Both the backend and site maintenance teams actively
              contribute to their success. The recent eco-friendly initiative,
              coupled with a delightful children's cultural event, garnered
              widespread enjoyment among residents. The Managing Director's
              visionary initiative to implement waste segregation at its origin
              is being vigorously pursued by their dedicated staff, showing
              great potential for success."
            </p>
            <cite>- President, Isha Anandham</cite>
          </div>
        </div>
        <div className="testimonial">
          <div className="testimonial-content">
            <p>
              "The uniqueness about JK Facility Management comes from the fact
              that this company is not started to do Business but created out of
              passion. JK work on optimising expenses by bringing in efficiency
              in everything they do starting with power audit, water audit, STP
              audit, resource optimization etc. They have been growing rapidly
              since they established and that shows their unique proposition
              they are offering to clients. I'm very happy to refer this company
              to every apartment to have smooth operations."
            </p>
            <cite>- President, TVS</cite>
          </div>
        </div>
        {/* Add more testimonials here */}
      </div>
    </section>
  );
};

export default Testimonial;
