import React from "react";
import { Link } from "react-router-dom";
//import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
//import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
//import 'bootstrap-icons/font/bootstrap-icons.css';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Slide1 from "../assets/img/JK_Home_Slide1.jpg";
import Slide2 from "../assets/img/JK_Home_Slide2.jpg";
import Slide3 from "../assets/img/JK_Home_Slide3.jpg";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const Hero = () => {
  return (
    <section id="hero">
      <div
        id="heroCarousel"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner" role="listbox">
          {/* Slide 1 */}
          <div
            className="carousel-item active"
            style={{ backgroundImage: `url(${Slide1})` }}
          >
            <div className="carousel-container">
              <div className="container">
                <h2 className="animate__animated animate__fadeInDown">
                  High-Quality Facility Services
                </h2>
                <p className="animate__animated animate__fadeInUp">
                  We provide a profession that encompasses multiple disciplines
                  to ensure functionality, comfort, safety, and efficiency of
                  the built environment by integrating people, place, process,
                  and technology.
                </p>
                <Link
                  to="/about"
                  className="btn-get-started animate__animated animate__fadeInUp scrollto"
                  onClick={scrollToTop}
                >
                  Know More About JK
                </Link>
              </div>
            </div>
          </div>

          {/* Slide 2 */}
          <div
            className="carousel-item"
            style={{ backgroundImage: `url(${Slide2})` }}
          >
            <div className="carousel-container">
              <div className="container">
                <h2 className="animate__animated animate__fadeInDown">
                  Committed to Excellence in Maintenance
                </h2>
                <p className="animate__animated animate__fadeInUp">
                  JK provides different maintenance services based on your
                  requirements and area of specialty. We retain and restore the
                  functionality of corporate and apartment properties in the
                  best manner.
                </p>
                <Link
                  to="/technicalsupport"
                  className="btn-get-started animate__animated animate__fadeInUp scrollto"
                  onClick={scrollToTop}
                >
                  Services by JK
                </Link>
              </div>
            </div>
          </div>

          {/* Slide 3 */}
          <div
            className="carousel-item"
            style={{ backgroundImage: `url(${Slide3})` }}
          >
            <div className="carousel-container">
              <div className="container">
                <h2 className="animate__animated animate__fadeInDown">
                  Pioneer of Security Services
                </h2>
                <p className="animate__animated animate__fadeInUp">
                  JK Security Guard Services provides value-added security
                  services with trained guards and supervisors with Register
                  maintenance skills, Employee background checking, Fire
                  Fighting skills, Evacuating procedures, Fire protection, and
                  Electronic security services.
                </p>
                <Link
                  to="/security"
                  className="btn-get-started animate__animated animate__fadeInUp scrollto"
                  onClick={scrollToTop}
                >
                  JK Security Services
                </Link>
              </div>
            </div>
          </div>
        </div>

        <a
          className="carousel-control-prev"
          href="#heroCarousel"
          role="button"
          data-bs-slide="prev"
        >
          <BsChevronLeft />
        </a>

        <a
          className="carousel-control-next"
          href="#heroCarousel"
          role="button"
          data-bs-slide="next"
        >
          <BsChevronRight />
        </a>

        {/* Indicators */}
        <ol className="carousel-indicators">
          <li
            data-bs-target="#heroCarousel"
            data-bs-slide-to="0"
            className="active"
          ></li>
          <li data-bs-target="#heroCarousel" data-bs-slide-to="1"></li>
          <li data-bs-target="#heroCarousel" data-bs-slide-to="2"></li>
        </ol>
      </div>
    </section>
  );
};

export default Hero;
