import React, { useState, useEffect, useRef } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../assets/img/logo1.png";

function ScrollToTopOnPageChange() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navbarRef = useRef(null);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    };
    setResponsiveness();
    window.addEventListener("resize", setResponsiveness);
    return () => {
      window.removeEventListener("resize", setResponsiveness);
    };
  }, []);

  const handleMouseEnter = () => {
    if (!isMobile) setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    if (!isMobile) setShowDropdown(false);
  };

  const toggleDropdown = () => {
    if (isMobile) setShowDropdown(!showDropdown);
  };

  const handleNavbarToggle = (isOpen) => {
    if (!isOpen && showDropdown) setShowDropdown(false);
  };

  const closeDropdown = () => {
    if (!showDropdown) return;
    setShowDropdown(false);
  };

  useEffect(() => {
    const handleNavbarCollapse = () => {
      if (!showDropdown) return;
      setShowDropdown(false);
    };

    const navbarElement = navbarRef.current;
    if (navbarElement) {
      navbarElement.addEventListener("hide", handleNavbarCollapse);
      return () => {
        navbarElement.removeEventListener("hide", handleNavbarCollapse);
      };
    }
  }, [showDropdown]);

  const closeDropdownOnClick = () => {
    setShowDropdown(false);
  };

  return (
    <>
      <ScrollToTopOnPageChange />
      <Navbar
        id="header"
        className="fixed-top"
        expand="lg"
        collapseOnSelect
        onMouseLeave={closeDropdown}
        ref={navbarRef}
        onToggle={handleNavbarToggle}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src={logo}
              alt="JK"
              className="img-fluid"
              style={{ maxWidth: "150px", maxHeight: "35px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar" />
          <Navbar.Collapse id="navbar">
            <Nav className="me-auto">
              <Nav.Link as={NavLink} to="/" onClick={closeDropdownOnClick}>
                Home
              </Nav.Link>
              <NavDropdown
                title="Services"
                id="basic-nav-dropdown"
                show={showDropdown}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={toggleDropdown}
              >
                <NavDropdown.Item
                  as={Link}
                  to="/facilitymanagement"
                  onClick={() => {
                    closeDropdownOnClick();
                  }}
                >
                  Facility Management Services
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/housekeeping">
                  Housekeeping Services
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/security">
                  Security Services
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hospitality">
                  Hospitality Services
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/technicalsupport">
                  Electro Mechanic Services
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/carpetcleaning">
                  Carpet Shampooing Services
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pestcontrol">
                  Pest Control Services
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/landscaping">
                  Landscaping Services
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/payrollmanagement">
                  Payroll Management Services
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/outsourcing">
                  Outsourcing Services
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/productionsupport">
                  Production Support Services
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/housecleaning">
                  House Cleaning Services
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={NavLink} to="/verticals">
                Verticals
              </Nav.Link>
              <Nav.Link as={NavLink} to="/qualitytraining">
                Quality Training
              </Nav.Link>
              <Nav.Link as={NavLink} to="/gallery">
                Gallery
              </Nav.Link>
              <Nav.Link as={NavLink} to="/about">
                About
              </Nav.Link>
              <Nav.Link as={NavLink} to="/contact">
                Contact
              </Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link
                href="https://wa.link/6sof8v"
                target="_blank"
                className="getstarted"
                style={{ padding: "8px 25px" }}
              >
                WhatsApp
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
