import React, { useState } from "react";
import emailjs from "emailjs-com";
import Title from "../components/Title";
import Header from "../components/header";
import Footer from "../components/footer";
import { Breadcrumb } from "../components/Breadcrumb";
import BackToTop from "../components/Backtotop";

const Contact = () => {
  return (
    <>
      <Title title="Contact - JK Facility Services" />
      <Header />
      <Breadcrumb pagename="Contact" />
      <div>
        {/* Google Maps Location */}
        <iframe
          title="Google Maps Location"
          style={{ border: 0, width: "100%", height: "350px" }}
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d343.7667166304546!2d80.11140123089326!3d12.889938777660731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1709662765793!5m2!1sen!2sin"
          width="800"
          height="600"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <section id="contact" className="contact">
        <div className="container">
          <div className="row mt-6">
            {/* Contact Information */}
            <div className="col-lg-4">
              <ContactInfo />
            </div>
            {/* Contact Form */}
            <div className="col-lg-6 mt-5 mt-lg-0">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
      <BackToTop />
      <Footer />
    </>
  );
};

const ContactInfo = () => {
  return (
    <div className="info">
      <Address />
      <Email />
      <Phone />
    </div>
  );
};

const Address = () => {
  return (
    <div className="address">
      <i className="bi bi-geo"></i>
      <h4>Location:</h4>
      <p>
        No.34/6, JK Complex,<br /> TVS Main Road,
        Alapakkam, <br /> New Perunglathur, Chennai - 600 063,<br />  Tamilnadu, INDIA.
      </p>
    </div>
  );
};

const Email = () => {
  return (
    <div className="email">
      <i className="bi bi-envelope-fill"></i>
      <h4>Email:</h4>
      <p>
        <a href="mailto:enquiry@rsplglobalservices.com?subject=Contact from website">
        enquiry@jkfacilityservices.com
        </a>
      </p>
    </div>
  );
};

const Phone = () => {
  return (
    <div className="phone">
      <i className="bi bi-telephone-fill"></i>
      <h4>Phone:</h4>
      <p>
        <strong>+91-7299573102, +91-9940242778</strong>
      </p>
    </div>
  );
};

const FormField = ({ label, type, name }) => {
  return (
    <div className="col-md-6 form-group">
      <input
        type={type}
        name={name}
        className="form-control"
        placeholder={label}
        required
      />
    </div>
  );
};

const ContactForm = () => {
  const [message, setMessage] = useState({ text: "", type: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });

    // Set default from_name if it's empty
    if (!data.from_name) {
      data.from_name = "JK Facility Services";
    }

    try {
      const result = await emailjs.send(
        "service_15tyiej",
        "template_aw1zfpt",
        data,
        "e9W1Ll3tiK3pjJQMv"
      );
      console.log(result.text);
      showMessage("Message sent successfully!", "success");
    } catch (error) {
      console.error(error.text);
      showMessage("Failed to send message. Please try again later.", "error");
    }

    // Clear the form after submission (optional)
    e.target.reset();
  };

  const showMessage = (text, type) => {
    setMessage({ text, type });

    // Hide the message after a certain duration (e.g., 3 seconds)
    setTimeout(() => {
      setMessage({ text: "", type: "" });
    }, 3000);
  };

  return (
    <div>
      {message.text && (
        <div className={`message ${message.type}`}>{message.text}</div>
      )}

      <form onSubmit={handleSubmit} className="php-email-form">
        <div className="row">
          {/* Assuming FormField is a custom component you have */}
          <FormField label="Your Name" type="text" name="from_name" />
          <FormField label="Your Phone Number" type="tel" name="phone"/>
        </div>

        <FormField label="Your Email ID" type="email" name="email" />

        <div className="form-group mt-3">
          <textarea
            className="form-control"
            name="message"
            rows="5"
            placeholder="Message"
            required
          ></textarea>
        </div>

        <div className="text-center">
          <button id="sendMessageButton" type="submit">
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
