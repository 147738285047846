import React from "react";
import Title from "../components/Title";
import Header from "../components/header";
import Footer from "../components/footer";
import Backtotop from "../components/Backtotop";
import { Breadcrumb } from "../components/Breadcrumb";

const AboutPage = () => {
  return (
    <div>
      <Title title={"About - JK Facility Services"} />
      <Header />
      <Breadcrumb pagename={"About"} />
      <section id="about" className="about"> {/* Use className instead of class */}
        <div className="container"> {/* Use className instead of class */}
          <div className="row content"> {/* Use className instead of class */}
            <div className="col-lg-6">
              <h2>We deliver unparalleled Integrated Facility Services across diverse sectors throughout South India, ensuring excellence in every aspect.</h2>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <p>JK Facility Management is a well-established enterprise specializing in delivering top-notch Facility Management Services, Security Services, Housekeeping Services, Apartment Maintenance, Technical Support, and Pest Control Services across South India.
                <p><strong>We excel in a profession that integrates various disciplines to ensure the seamless functionality, comfort, safety, and efficiency of the built environment by harmonizing people, place, process, and technology.</strong></p>
              </p>
              <ul>
                <li><i className="ri-check-double-line"></i> Our services are competitively priced and renowned for unparalleled quality</li>
                <li><i className="ri-check-double-line"></i> Highly experienced professionals adhering to strict industry standards</li>
                <li><i className="ri-check-double-line"></i> Employees undergo rigorous training through diverse workshops and seminars</li>
              </ul>
              <p>Regular intervals of training sessions enhance and refine our employees' technical and professional skills systematically. This comprehensive training approach ensures seamless coordination among our team members, mitigating workplace challenges effectively.</p>
              <p className="fst-italic">Our professional team comprises skilled and semi-skilled labor, warehousing experts, packaging specialists, logistics professionals, procurement agents, and dedicated sales & marketing executives.</p>
            </div>
          </div>
        </div>
      </section>
      <Backtotop />
      <Footer />
    </div>
  );
};

export default AboutPage; // Use camelCase for component names (AboutPage instead of Aboutpage)
