import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');

const root = rootElement.createRoot ? rootElement.createRoot() : ReactDOM;

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement
);
reportWebVitals();
