import React from "react";
import Title from "../components/Title";
import Header from "../components/header";
import Footer from "../components/footer";
import Backtotop from "../components/Backtotop";
import { Breadcrumb } from "../components/Breadcrumb";

const Gallery = () => {
  return (
    <div>
      <Title title={"Gallery - JK Facility Services"} />
      <Header />
      <Breadcrumb pagename={"Gallery"} />
      <Backtotop />
      <Footer />
    </div>
  );
};

export default Gallery;
